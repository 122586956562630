
.backdrop-enter {
    transition: all 300ms ease-out;
}

.backdrop-enter-from, backdrop-leave-to {
    opacity: 0;
}

.backdrop-enter-to, .backdrop-leave-from {
    opacity: 100%;
}

.backdrop-leave {
    transition: all 1200ms ease-in;
}


.panel-enter {
    transition: all 300ms ease-out;
}

.panel-enter-from, panel-leave-to {
    opacity: 0;
    transform: scale(.95);
}

.panel-enter-to, .panel-leave-from {
    opacity: 100%;
    transform: scale(1);
}

.panel-leave {
    transition: all 1200ms ease-in;
}