@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

html {
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #656a76;
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px !important;
  }
}

.highcharts-plot-background {
  stroke: #d9dbdd;
}

.highcharts-legend {
  pointer-events: none !important;
}